// 单文件上传

<template>
  <div class="upload">
    <el-upload
      ref="uploadFile"
      :headers="headers"
      :action="action"
      name="attach"
      :accept="accept"
      :limit="10"
      :file-list="fileList"
      class="uploader-file"
      :before-upload="beforeUpload"
      :on-exceed="onExceed"
      :on-success="onSuccess"
      :on-remove="onRemove"
    >
      <slot name="btn"></slot>
      <div v-if="tip" slot="tip" class="el-upload__tip">{{ tip }}</div>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {
    file: { type: Object, default: null },
    firmware: { type: Boolean, default: false },
    accept: { type: String, default: '' },
    size: { type: [Number, String], default: 300 },
    disabled: { type: Boolean, default: false },
    tip: { type: String, default: '' }
  },

  data() {
    return {
      headers: { Authorization: localStorage.getItem('token') },
      // action: process.env.VUE_APP_BASE_API + '/serve-user/app-api/v1/upload' + this.firmware ? '/firmware' : '',
      action: `${process.env.VUE_APP_BASE_API}/serve-user/app-api/v1/upload${this.firmware ? '/firmware' : ''}`,

      fileList: []
    }
  },

  computed: {},

  watch: {
    file: {
      handler(file) {
        if (file) {
          this.fileList = [file]
        } else {
          this.fileList = []
        }
      },
      immediate: true
      // deep: true
    }
  },

  created() {},

  methods: {
    // 上传前触发
    beforeUpload(file) {
      const isLtSize = file.size / 1024 / 1024 < this.size

      if (!isLtSize) {
        this.$message.error('上传文件的大小不能超过 ' + this.size + 'MB!')
      }

      return isLtSize
    },

    // 文件个数超出限制时触发
    onExceed() {
      this.$message.error('最多只能上传 1 个文件！请删除后再试。')
    },

    // 上传成功时触发
    onSuccess(res, file) {
      this.fileList = []

      if (this.firmware) {
        this.fileList.push({
          name: res.data.fileName,
          url: res.data.fileUrl,
          checkCode: res.data.checkCode
        })
      } else {
        this.fileList.push({
          name: file.name,
          url: res.data[0]
        })
      }

      this.$emit('change', this.fileList[0])
    },

    // 删除文件
    onRemove(file) {
      this.fileList = []
      this.$emit('change', null)
    }
  }
}
</script>

<style scoped lang="scss">
.upload {
  line-height: normal;

  /deep/ .uploader-file {
    .el-upload {
    }

    .el-upload__tip {
    }
  }
}
</style>
